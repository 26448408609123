export const mockAdminToken = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImNzYWxkYW5oYUBsb3lhbHR5LmNvbSIsIm5hbWUiOiJDbGl2ZSBTYWxkYW5oYSIsInJvbGUiOiJBZG1pbmlzdHJhdG9yIiwicGVybWlzc2lvbnMiOlsiQ1JFQVRFX09GRkVSIiwiVklFV19PRkZFUiIsIkVESVRfT0ZGRVIiLCJQVUJMSVNIX09GRkVSIiwiREVMRVRFX09GRkVSIiwiRElTQUJMRV9PRkZFUiIsIkNSRUFURV9VU0VSIiwiRURJVF9VU0VSIiwiRElTQUJMRV9VU0VSIiwiQ1JFQVRFX1JPTEUiLCJFRElUX1JPTEUiLCJERUxFVEVfUk9MRSIsIkNSRUFURV9HUk9VUCIsIkVESVRfR1JPVVAiLCJERUxFVEVfR1JPVVAiXSwiaWF0IjoxNTQ5OTk4NDg5LCJleHAiOjE1NDk5OTkzODl9.0rEkLU7ULykTLj2pBSqKafV8coxTH7chVgnUJaXgktY`;
export const mockReviewerToken = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImNzYWxkYW5oYUBsb3lhbHR5LmNvbSIsIm5hbWUiOiJDbGl2ZSBTYWxkYW5oYSIsInJvbGUiOiJSZXZpZXdlciIsInBlcm1pc3Npb25zIjpbIlZJRVdfT0ZGRVIiXSwiaWF0IjoxNTQ5OTk4NDg5LCJleHAiOjE1NDk5OTkzODl9.Yo2hZ1bUJ9C9-m_S2NbBMXYJlPxN0GkEgkGDelYbw9Y`;
export const mockPublisherToken = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImNzYWxkYW5oYUBsb3lhbHR5LmNvbSIsIm5hbWUiOiJDbGl2ZSBTYWxkYW5oYSIsInJvbGUiOiJQdWJsaXNoZXIiLCJwZXJtaXNzaW9ucyI6WyJDUkVBVEVfT0ZGRVIiLCJWSUVXX09GRkVSIiwiRURJVF9PRkZFUiIsIlBVQkxJU0hfT0ZGRVIiLCJESVNBQkxFX09GRkVSIl0sImlhdCI6MTU0OTk5ODQ4OSwiZXhwIjoxNTQ5OTk5Mzg5fQ.cOEyvw2uOsHV75YvZXhJ-eAkPSuMjsVEUZbk1IPAb5c`;
export const mockBasicToken = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImNzYWxkYW5oYUBsb3lhbHR5LmNvbSIsIm5hbWUiOiJDbGl2ZSBTYWxkYW5oYSIsInJvbGUiOiJVbmFzc2lnbmVkIiwicGVybWlzc2lvbnMiOltdLCJpYXQiOjE1NDk5OTg0ODksImV4cCI6MTU0OTk5OTM4OX0.6qLE2bf9H8G7T6I0gBaYLr_K29rS7Kd49xsWn1QPNIw`;

export const CONSTANTS = {
  defaultOffer: "Default All"
};

export const postImageApi = process.env.REACT_APP_POST_IMAGE_API;

export const postSecurityApi = process.env.REACT_APP_POST_SECURITY_API;

export const offerFacetsApi = process.env.REACT_APP_OFFER_FACETS_API;

export const offerPromotionsApi = process.env.REACT_APP_OFFER_PROMOTIONS_API;

export const offerSubmissionApi = process.env.REACT_APP_OFFER_SUBMISSION_API;

export const pingLoginUrl = process.env.REACT_APP_PING_LOGIN_URL;

export const offerIssuerApi = process.env.REACT_APP_OFFER_ISSUER_API;

export const urls = {
  groupsController: {
    groups: `${postSecurityApi}/groups`
  },
  imagesController: {
    processImages: `${postImageApi}/v1/process-images`,
    generateS3Url: `${postImageApi}/v1/generate-s3-url`
  },
  login: "/login",
  logout: "/logout",
  offersController: {
    getBulkJobs: `${postSecurityApi}/v1/offers/jobs`,
    offers: `${postSecurityApi}/v1/offers`,
    bulkPublishOffers: `${postSecurityApi}/v1/offers/bulk/publish`,
    publishOffer: `${postSecurityApi}/v1/offers/publish`,
    uploadOffers: `${postSecurityApi}/v1/offers/bulk`,
    updateBulkOffers: `${postSecurityApi}/v1/offers/bulk`,
    generateContent: `${postSecurityApi}/v1/offers/generate-content`,
    postOffer: `${postSecurityApi}/v1/offers`,
    getCategories: `${offerFacetsApi}/v1/categories`,
    getPromotions: `${offerPromotionsApi}/v1/promotions`,
    getOffersCounts: `${postSecurityApi}/v1/offers/counts`,
    deleteOffers: `${postSecurityApi}/v1/offers/delete-multiple`,
    disableOffer: `${postSecurityApi}/v1/offers/disable`,
    targetedOffers: `${postSecurityApi}/v1/offers/targeted`,
    enableOffer: `${postSecurityApi}/v1/offers/enable`
  },
  partnersController: {
    partners: `${postSecurityApi}/partners`
  },
  permissionController: {
    permissionConfig: "/config/permissions.json",
    permissions: `${offerSubmissionApi}/permissions`
  },

  pingAuthorizer: pingLoginUrl,
  rolesController: {
    roles: `${offerSubmissionApi}/roles`
  },

  userController: {
    groups: (email: string) => `${postSecurityApi}/users/${email}/groups`,
    partners: (email: string) => `${postSecurityApi}/users/${email}/partners`,
    users: `${offerSubmissionApi}/users`
  },

  offersIssuer: {
    activeSponsorCodes: `${offerIssuerApi}/issuers`,
    issuanceOfferCodes: `${offerIssuerApi}/issuer-offers`,
  }
};

export const BARCODE_MECHANISM_TYPES = [
  "barcodeUPC",
  "barcodeCODE128",
  "barcodeEAN",
  "barcodeCODE39"
];