import {ILocalizedObject, IValidationErrors} from "./validator";
import * as validationErrorGenerator from "./validatorErrorFactory";

export interface ICtaObject {
    field: string,
    fieldLanguage: string,
    value?: string | null,
    required: boolean
}

/*
Extracts data from a {"en-US": value, "fr-CA": value} object with a specified name and put
that data into a {field: "ctaLabel", fieldLanguage: "ctaLabel.en-US", value: value} object
*/
export const extractCtaArrayValues = (ctaObject: ILocalizedObject | {}, objectName: string, ctaValuesResultingArray: ICtaObject[]) => {
    if(ctaObject !== {}) {
        Object.keys(ctaObject).forEach(
            (key: string) => {
                const fieldName = `${objectName}.${key}`;

                const ctaReference = ctaValuesResultingArray.find(object => object.fieldLanguage === fieldName);
                if (ctaReference) {
                    ctaReference.value = ctaObject[key];
                }
            }
        );
    }
}

export const pushCtaRequiredError = (values: ICtaObject[], errorArray: IValidationErrors[]) => {
    values.forEach(
        object => {
            const ctaRequiredValueError = validationErrorGenerator.generateCtaRequiredError(
                object.field,
                object.fieldLanguage
            );

            errorArray.push(ctaRequiredValueError);
        }
    );
}